import http from '@utils/http';


export default {
  getStrengthDescription() {
    return new Promise((resolve, reject) => {
      http.get(`strength-description/${process.env.QUESTIONNAIRE_ID}`).then(
        ({ data }) => {
          resolve(data);
        },
      ).catch((error) => {
        reject(error);
      });
    });
  },
};
