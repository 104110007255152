// only cut version
import api from '@api';

const getters = {
  getGeneralSurveyData: state => state.generalData,
};

const actions = {
  saveSurveyData({ commit }, code) {
    return api.questionnaireCutVersion.saveSurveyData(code)
      .then((res) => {
        commit('saveSurveyData', res);
      });
  },
  updateSurveyData({ commit }, code) {
    return api.questionnaireCutVersion.getDataCompletingQuiz(code)
      .then((res) => {
        commit('saveSurveyData', res);
      });
  },
};

const mutations = {
  saveSurveyData(state, data) {
    state.generalData = data;
  },
};

const state = {
  generalData: {},
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
