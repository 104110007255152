<template>
  <v-app class="main">
    <Header :image="smallLogo"
            :style="{background: this.$route.name === 'questionnaire' ?
             'rgb(243,244,246)' : '#F3F4F6'}"
            class="main-container"/>
    <HeaderAdmin v-if="checkRoleAdmin"/>
    <LostConnectionWindowModal
      :showing-modal="showWindowModalLostConnect"
      @retry="updateOnlineStatus(checkOnlineWithoutEvent())"
    />
    <router-view class="main__content" :key="updateContent"/>
    <Notification />
  </v-app>
</template>

<script>
import HeaderAdmin from '@components/Header/HeaderAdmin.vue';
import smallLogo from '@assets/small_logo.svg';
import Notification from '@components/Notification/Notification.vue';
import checkRole from '@helpers/adminFunction';
import Header from '@components/Header/Header.vue';
import LostConnectionWindowModal from '@innerworks_ds/innerworks-storybook-main/src/stories/WindowModals/LostConnectionWindowModal/LostConnectionWindowModal.vue';

export default {
  name: 'App',
  components: {
    Header,
    Notification,
    HeaderAdmin,
    LostConnectionWindowModal,
  },
  data: () => ({
    smallLogo,
    onLine: null,
    showWindowModalLostConnect: false,
    updateContent: 0,
  }),
  mounted() {
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
    this.updateOnlineStatus(this.checkOnlineWithoutEvent());
  },
  beforeDestroy() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  },
  methods: {
    updateOnlineStatus(e) {
      const {
        type,
      } = e;

      this.onLine = type === 'online';
    },
    checkOnlineWithoutEvent() {
      return { type: navigator.onLine ? 'online' : 'offline' };
    },
  },
  watch: {
    onLine(v) {
      if (v) {
        this.showWindowModalLostConnect = false;
        this.updateContent = this.updateContent + 1;
      } else {
        this.showWindowModalLostConnect = true;
      }
    },
  },
  computed: {
    title() {
      if (process.env.VUE_APP_TITLE) {
        return process.env.VUE_APP_TITLE;
      }
      return 'InnerWorks';
    },
    showSecretDataModal() {
      return this.getProfile.userWithoutSecretAnswer === false;
    },
    checkRoleAdmin() {
      return !!checkRole.isAdmin();
    },
  },
  metaInfo() {
    return {
      title: this.title,
    };
  },
};
</script>

<style lang="scss">
  @import "./styles/style.scss";
  .container {
    padding: 0;
    background-color: #F3F4F6;
  }
  .main-container {
    max-width: 480px !important;
    margin: 0 auto;
    width: 100%;
  }
  .grecaptcha-badge {
    visibility: hidden;
  }
</style>
