const getters = {
  getStateResultPage: state => state.resultPage,
  isNotCrowdTabResultPage: state => state.resultPage.isNotCrowdTab,
};


const actions = {
  changeCrowdTab({ commit }, data) {
    commit('changeCrowdTab', data);
  },
  resetStateResultPage({ commit }) {
    commit('resetStateResultPage');
  },
};

const mutations = {
  changeCrowdTab(state, data) {
    state.resultPage = {
      isNotCrowdTab: data,
    };
  },
  resetStateResultPage(state) {
    state.resultPage = {
      isNotCrowdTab: true,
    };
  },
};

const state = {
  resultPage: {
    isNotCrowdTab: true,
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
