import api from '@api';

const getters = {
  getFacets: state => state.facets,
  getQuestionnaireData: state => state.questionnaireData,
  getLastAnswer: state => state.lastAnswer,
};

const mappingColor = {
  '-5': 'rgb(227,215,237)',
  '-4.5': 'rgb(229,219,239)',
  '-4': 'rgb(231,221,241)',
  '-3.5': 'rgb(234,228,243)',
  '-3': 'rgb(238,235,244)',
  '-2.5': 'rgb(238,235,244)',
  '-2': 'rgb(239,237,245)',
  '-1.5': 'rgb(239,237,245)',
  '-1': 'rgb(241,239,245)',
  '-0.5': 'rgb(242,242,246)',
  0: 'rgb(243,244,246)',
  0.5: 'rgb(242,242,246)',
  1: 'rgb(241,239,245)',
  1.5: 'rgb(239,237,245)',
  2: 'rgb(239,237,245)',
  2.5: 'rgb(238,235,244)',
  3: 'rgb(237,233,244)',
  3.5: 'rgb(234,228,243)',
  4: 'rgb(231,221,241)',
  4.5: 'rgb(229,219,239)',
  5: 'rgb(227,215,237)',
};

const actions = {
  saveFacets({ commit }, data) {
    return api.questionnaire.calculatePartialResult(data)
      .then((res) => {
        commit('setFacets', { name: res, self: data.self });
      });
  },
  updateFacets({ commit }, data) {
    commit('updateFacetsCount', data);
  },
  updateQuestionnaireData({ commit }, data) {
    commit('updateQuestionnaireData', data);
  },
  saveLastAnswer({ commit }, data) {
    commit('saveLastAnswer', data);
  },
};

const mutations = {
  setFacets(state, data) {
    if (data.self) {
      if (state.facets.name === data.name) {
        state.facets.name = data.name;
        state.facets.count += 1;
      } else {
        state.facets.name = data.name;
        state.facets.count = 1;
        state.facets.general = false;
      }
    } else {
      if (!state.facets.count) {
        state.facets.count = 0;
      }
      state.facets.count += 1;
      state.facets.general = true;
    }
  },
  updateFacetsCount(state, data) {
    state.facets = {
      ...state.facets,
      count: data.count,
      general: data.general,
    };
  },
  updateQuestionnaireData(state, data) {
    state.questionnaireData = {
      currentQuestionNumber: data.currentIndex,
      countOfQuestion: data.countOfQuestion,
      showProgressBar: data.showProgressBar,
      questionBeforeCheckpointScreen: data.questionBeforeCheckpointScreen,
      numberCheckPointScreen: data.numberCheckPointScreen,
    };
  },
  saveLastAnswer(state, data) {
    state.lastAnswer = {
      numberOfAnswer: data,
      color: mappingColor[data],
    };
  },
};

const state = {
  facets: {},
  questionnaireData: {
    currentQuestionNumber: 0,
    countOfQuestion: 0,
    showProgressBar: false,
  },
  lastAnswer: {
    numberOfAnswer: 0,
    color: 'rgba(120, 17, 201, 0.050)',
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
