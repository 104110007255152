import http from '@utils/http';

export default {
  sendTermConditionEmail(userEmail) {
    return new Promise((resolve, reject) => {
      http.get(`/term-condition/send-term-condition-email?userEmail=${userEmail}`).then(
        ({ data }) => {
          resolve(data);
        },
      ).catch((error) => {
        reject(error);
      });
    });
  },
};
