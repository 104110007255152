<template>
  <span>
  <DetectOthersAccounts
    @no-event-button="cancelSecondaryAccount"
    @confirm-modal="confirmLogout"
    :operation-system="getProfile.lastDevice"
    :browser="getProfile.lastLoginBrowser"
    :showing-modal="detected2AutorizedAccounts"/>
  <LoginRegisterWindowModal
    :showingModal.sync="isShowLoginModal"
    @show-modal="showLoginModal"
    @login-data="login"
    @complete-validation-forgot-password="resetPassword"
    @registration-data="registerAccount"
    @resent-activate-link="resentActivateLink"
    @change-email="changeEmailRegister"
    :errorNotFoundEmailLogin="errorNotFoundEmailLogin"
    :errorPasswordIncorrect="errorPasswordIncorrect"
    :messageActivateSend="messageActivateSend"
    :registerError="registerError"
    :loginLoading="loginLoading"
    :forgotPassLoading="forgotPassLoading"
    :registerLoading="registerLoading"
  />
  </span>
</template>

<script>
import LoginRegisterWindowModal from '@innerworks_ds/innerworks-storybook-main/src/stories/WindowModals/LoginRegisterWindowModal/LoginRegisterWindowModal.vue';
import checkRole from '@helpers/adminFunction';
import differentsServices from '@helpers/differentsServices';
import { mapGetters } from 'vuex';
import helpFunction from '@helpers/helpFunction';
import DetectOthersAccounts from '@innerworks_ds/innerworks-storybook-main/src/stories/WindowModals/DetectOthersAccount/DetectOthersAccounts.vue';

export default {
  name: 'LoginAndRegisterWindowModal',
  components: {
    LoginRegisterWindowModal,
    DetectOthersAccounts,
  },
  data: () => ({
    isShowLoginModal: true,
    openPopupModal: true,
    errorNotFoundEmailLogin: false,
    errorPasswordIncorrect: null,
    messageActivateSend: false,
    registerError: null,
    loginLoading: false,
    forgotPassLoading: false,
    registerLoading: false,
    detected2AutorizedAccounts: false,
  }),
  computed: {
    ...mapGetters({
      getProfile: 'auth/getProfile',
      getInvitationData: 'invitation/getInvitationData',
      getSupporteesResultUserId: 'auth/getSupporteesResultUserId',
    }),
  },
  methods: {
    changeEmailRegister(value) {
      this.messageActivateSend = value;
    },
    async prepareDataForRequestRegister(checkboxInform, formData) {
      return {
        name: this.getProfile.name ? this.getProfile.name : '',
        surname: '',
        youEmail: formData.mail,
        questionId: this.getProfile.completedQuestionnaires
          ? this.getProfile.completedQuestionnaires[0] : null,
        uniqueId: localStorage.getItem('uniqueId'),
        fingerPrint: differentsServices.getClientData(),
        checkboxInform,
        registerWithActivateMessage: true,
      };
    },
    async registerAccount(formData) {
      this.registerError = null;
      this.registerLoading = true;
      const data = await this.prepareDataForRequestRegister(true, formData);
      try {
        await this.$store.dispatch('auth/preRegisterUser', data);
        await this.$store.dispatch('auth/setUserPortBeforeRegister', this.$route.path);
        // const geolocation = await differentsServices.getGeolocation();
        // const getGeoData = await differentsServices.requestSearchGeoPosition(geolocation);
        //
        // if (getGeoData.allowGetGeolocation) {
        //   await this.$api.auth.updateGeoLocationUsers(getGeoData, true, this.getProfile.id);
        // } else if (!getGeoData.allowGetGeolocation) {
        //   await this.$api.auth.updateGeoLocationUsers(getGeoData, false, this.getProfile.id);
        // }

        this.messageActivateSend = true;
        this.registerLoading = false;
      } catch (e) {
        this.registerError = e.response.data.message;
        this.registerLoading = false;
      }
    },
    showLoginModal(value) {
      this.$emit('close-login-modal', value);
    },
    async resentActivateLink(mail) {
      await this.$store.dispatch('auth/preRegisterUser',
        {
          youEmail: mail,
          resendEmail: true,
          uniqueId: localStorage.getItem('uniqueId'),
        });
      this.messageActivateSend = true;
    },
    prepareDataForRequest(formData) {
      let uniqueId = null;
      if (localStorage.getItem('uniqueId') !== null) {
        uniqueId = localStorage.getItem('uniqueId');
      }

      return {
        password: formData.password,
        mail: formData.mail,
        uniqueId,
        questionId: process.env.QUESTIONNAIRE_ID,
        fingerPrintData: differentsServices.getClientData(),
      };
    },
    savePhoto(userWithoutPhoto, email, id) {
      if (userWithoutPhoto) {
        differentsServices.avatarPhotoService(email, id);
      }
    },
    async resetPassword(formData) {
      this.forgotPassLoading = true;
      this.sendingResetPasswrod = true;
      try {
        await this.$store.dispatch('auth/resetPasswordRequest', { email: formData.mail });
        this.sendingResetPasswrod = false;
        this.forgotPassLoading = false;
      } catch (error) {
        this.sendingResetPasswrod = false;
        this.forgotPassLoading = false;
      }
    },
    async cancelSecondaryAccount() {
      await this.$api.user.logoutFromAnotherAccounts(false,
        differentsServices.getClientData());
      this.$store.dispatch('auth/logoutOnlyCurrentDevice');
      this.$emit('close-login-modal', false);
      this.detected2AutorizedAccounts = false;
      this.$router.push({
        name: 'main',
        params: {
          slide: 3,
        },
      });
    },
    async confirmLogout() {
      const currentDevice = differentsServices.getClientData();
      const newToken = await this.$api.user.logoutFromAnotherAccounts(true, currentDevice);
      await this.$store.dispatch('auth/updateToken', { newToken, currentDevice });
      this.redirectAfterLogin();
      this.detected2AutorizedAccounts = false;
    },
    getCode(param) {
      const urlString = window.location.href;
      const url = new URL(urlString);
      return url.searchParams.get(param);
    },
    redirectAfterLogin() {
      const resultPage = this.getCode('result-page');
      const isU2Result = this.getCode('is-u2-result');

      const {
        completedQuestionnaires,
      } = this.getProfile;

      if (checkRole.isAdmin()) {
        this.$router.push({
          name: 'adminMenu',
        });
      } else if (helpFunction.isFillObject(this.getInvitationData)) {
        this.$router.push('main');
      } else if (resultPage || this.getSupporteesResultUserId.userId) {
        this.$router.push('/result');
        if (isU2Result || this.getSupporteesResultUserId.userId) {
          this.$store.dispatch('behaviorComponents/changeCrowdTab', false);
        } else {
          this.$store.dispatch('behaviorComponents/changeCrowdTab', true);
        }
      } else if (completedQuestionnaires.includes(process.env.QUESTIONNAIRE_ID)) {
        this.$router.push('/result');
      } else if (!checkRole.isAdmin()) {
        this.$router.push({
          name: 'main',
          params: {
            slide: 3,
          },
        });
      } else {
        this.$router.replace(this.getRedirectAuth);
      }
      this.isShowLoginModal = false;
      this.$emit('close-login-modal', false);
    },
    async login(formData) {
      this.loginLoading = true;
      const data = this.prepareDataForRequest(formData);
      this.errorNotFoundEmailLogin = false;
      this.errorPasswordIncorrect = null;
      try {
        await this.$store.dispatch('auth/loginRequest', data);

        // const geolocation = await differentsServices.getGeolocation();
        // const getGeoData = await differentsServices.requestSearchGeoPosition(geolocation);
        //
        // if (getGeoData.allowGetGeolocation) {
        //   await this.$api.auth.updateGeoLocationUsers(getGeoData, true, this.getProfile.id);
        // } else if (!getGeoData.allowGetGeolocation) {
        //   await this.$api.auth.updateGeoLocationUsers(getGeoData, false, this.getProfile.id);
        // }
        if (!this.getProfile.detectedSeveralAuthorization) {
          this.loginLoading = false;
          this.redirectAfterLogin();
        } else {
          this.detected2AutorizedAccounts = true;
        }
      } catch (error) {
        this.loginLoading = false;
        if (error.response.status === 404) {
          this.errorNotFoundEmailLogin = true;
        } else {
          this.errorPasswordIncorrect = error.response.data.message;
        }
      }
    },
  },
};
</script>

<style scoped>

</style>
