// eslint-disable-next-line consistent-return
module.exports = function tawkTo(email, name, propertyId = '5f9ad3827f0a8e57c2d83734') {
  if (!window) {
    throw new Error('DOM is unavailable');
  }
  window.Tawk_API = window.Tawk_API || {};

  window.Tawk_LoadStart = new Date();

  const tawk = document.getElementById('tawkId');
  if (tawk) {
    return window.Tawk_API;
  }

  const script = document.createElement('script');
  script.id = 'tawkId';
  script.async = true;
  script.src = `https://embed.tawk.to/${propertyId}/default`;
  script.charset = 'UTF-8';
  script.setAttribute('crossorigin', '*');

  const firstScriptTag = document.getElementsByTagName('script')[0];
  if (!firstScriptTag || !firstScriptTag.parentNode) {
    throw new Error('DOM is unavailable');
  }

  firstScriptTag.parentNode.insertBefore(script, firstScriptTag);
};
