import Vue from 'vue';
import Router from 'vue-router';
import RouterPrefetch from 'vue-router-prefetch';
import Store from '../store';

Vue.use(Router);
Vue.use(RouterPrefetch);

Vue.use(RouterPrefetch, {
  componentName: 'QuickLink',
});


export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '*',
      redirect: { name: 'main' },
    },
    {
      path: '/',
      redirect: { name: 'main' },
    },
    {
      path: '/main',
      name: 'main',
      props: true,
      component: () => import('@viewsCutVersion/Main.vue'),
      beforeEnter: (to, from, next) => {
        next();
      },
    },
    {
      path: '/questionnaire',
      name: 'questionnaire',
      component: () => import('@viewsCutVersion/Questionnaire.vue'),
      beforeEnter: (to, from, next) => {
        const { prolificId } = Store.getters['surveyData/getGeneralSurveyData'];

        if (prolificId) {
          next();
          return;
        }

        next('main');
      },
    },
    {
      path: '/result',
      name: 'result',
      component: () => import('@viewsCutVersion/ReportCutVersion.vue'),
      beforeEnter: (to, from, next) => {
        const { prolificId, numberOfTimesCompetedQuiz } = Store.getters['surveyData/getGeneralSurveyData'];


        if (!prolificId || numberOfTimesCompetedQuiz === 0) {
          next('main');
          return;
        }

        next();
      },
    },
  ],
});
