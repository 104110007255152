import api from '@api';

const getters = {
  getProfile: state => state.profile,
  getRedirectAuth: state => state.redirectAuth,
  getSecurityCode: state => state.securityCode,
  getSupporteesResultUserId: state => state.supporteesResultUserId,
};

const actions = {
  setSecurityCode({ commit, state }, data) {
    return api.auth.validateCode(data, state.profile.id).then(() => {
      commit('setSecurityCode', data.code);
    });
  },
  updatePhoneNumber({ commit, state }, data) {
    return api.auth.updatePhoneNumber(data, state.profile.id).then((res) => {
      commit('updatePhoneNumber', res);
    });
  },
  registerRequest({ commit }, data) {
    return api.auth.register(data).then((res) => {
      commit('setProfile', res);
    });
  },
  preRegisterUser({ commit }, formData) {
    return api.auth.preRegisterUser(formData).then((res) => {
      commit('setProfile', res);
    });
  },
  loginRequest({ commit }, data) {
    return api.auth.login(data).then((res) => {
      commit('setProfile', res);
    });
  },
  resetPasswordRequest({ commit }, data) {
    return api.auth.resetPassword(data).then((res) => {
      commit('setId', { id: res });
    });
  },
  newPassword({ commit }, data) {
    return api.auth.newPassword(data.formData).then((res) => {
      commit('setProfile', res);
    });
  },
  setSelfPersonalityTypeRequest({ commit }, formData) {
    return api.personalityTypeReport.setPersonalityType(formData).then(() => {
      commit('setSelfPersonalityType', formData.selfPersonalityType);
    });
  },
  setCompletedFeedbackRequest({ commit }, formData) {
    return api.feedback.setFeedBack(formData).then(() => {
      commit('setCompletedFeedback', process.env.FEEDBACK_ID);
    });
  },
  updateIdentificationData({ commit }, data) {
    commit('updateIdentificationData', data);
  },
  async logout({ commit, state }) {
    if (state.profile && state.profile.token) {
      try {
        await api.user.logout();
      } finally {
        commit('setProfile', {});
        commit('setRedirectAuth', 'questionnaire');
        localStorage.removeItem('uniqueId');
      }
    } else {
      commit('setProfile', {});
      commit('setRedirectAuth', 'questionnaire');
      localStorage.removeItem('uniqueId');
    }
  },
  logoutOnlyCurrentDevice({ commit }) {
    commit('setProfile', {});
    commit('setRedirectAuth', 'questionnaire');
    localStorage.removeItem('uniqueId');
  },
  deletedAccount({ commit }) {
    commit('setProfile', { showDeleteAccount: true });
    commit('setRedirectAuth', 'questionnaire');
  },
  setRedirectAuth({ commit }, path) {
    commit('setRedirectAuth', path);
  },
  setUserName({ commit }, data) {
    commit('setUserName', data);
  },
  setNotificationCrowdEmail({ commit }, data) {
    commit('setNotificationCrowdEmail', data);
  },
  updateProfile({ commit }, data) {
    commit('setProfile', data);
  },
  updateSavedSecretAnswers({ commit }) {
    commit('setSavedSecretAnswers', commit);
  },
  setUserPortBeforeRegister({ commit }, data) {
    commit('setUserPortBeforeRegister', data);
  },
  setSupporteesResultUserId({ commit }, data) {
    commit('setSupporteesResultUserId', data);
  },
  updateToken({ commit }, data) {
    commit('updateToken', data);
  },
};

const mutations = {
  setProfile(state, data) {
    state.profile = data;
  },
  setUserName(state, data) {
    state.profile = {
      ...state.profile,
      name: data,
    };
  },
  setNotificationCrowdEmail(state, data) {
    state.profile = {
      ...state.profile,
      latestNews: data.latestNews,
      email: data.email,
      notificationCrowdSentEmail: data.notificationCrowdSentEmail,
    };
  },
  setId(state, data) {
    state.profile = {
      ...state.profile,
      id: data.id,
    };
  },
  setSecurityCode(state, data) {
    state.securityCode = data;
  },
  setSelfPersonalityType(state, data) {
    state.profile.selfPersonalityType = data;
  },
  setCompletedFeedback(state, data) {
    state.profile.completedFeedbacks = [
      ...state.profile.completedFeedbacks,
      data,
    ];
  },
  updateToken(state, data) {
    state.profile = {
      ...state.profile,
      detectedSeveralAuthorization: false,
      token: data.newToken,
      lastDevice: data.currentDevice ? data.currentDevice.operationSystem : state.lastDevice,
      lastLoginBrowser: data.currentDevice ? data.currentDevice.browser : state.browser,
    };
  },
  updatePhoneNumber(state, data) {
    state.profile.phone = data.phone;
    state.profile.isoCountryCode = data.isoCountryCode;
    state.profile.codeCountry = data.codeCountry;
    state.profile.phoneReal = null;
    state.profile.token = data.token;
  },
  setRedirectAuth(state, path) {
    state.redirectAuth = path;
  },
  setSavedSecretAnswers(state) {
    state.profile = {
      ...state.profile,
      userWithoutSecretAnswer: true,
    };
  },
  updateIdentificationData(state, data) {
    state.profile = {
      ...state.profile,
      userWithIdentificationCard: data.userWithIdentificationCard,
      userWithCreditCard: data.userWithCreditCard,
    };
  },
  setUserPortBeforeRegister(state, data) {
    state.profile = {
      ...state.profile,
      routeBeforeRegister: data,
    };
  },
  setSupporteesResultUserId(state, data) {
    state.supporteesResultUserId = data;
  },
};

const state = {
  profile: {},
  securityCode: null,
  redirectAuth: 'questionnaire',
  supporteesResultUserId: {},
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
