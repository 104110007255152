import api from '@api';

const getters = {
  getPersonalityTest: state => state.personalityTest,
  getPersonalityTestForManager: state => state.personalityTest,
  getInvitationData: state => state.invitationData,
};

const actions = {
  setPersonalityTest({ commit }, data) {
    return api.questionnaire.saveInvitationAnswer(data.formData, data.id, data.uniqueId,
      data.requestParam, data.u1Email, data.notificationCrowdEmails)
      .then((res) => {
        commit('setPersonalityTest', res);
      });
  },
  setPersonalityTestForManager({ commit }, formData) {
    commit('setPersonalityTestForManager', formData);
  },
  setInvitationData({ commit }, data) {
    commit('setInvitationData', data);
  },
  updateInvitationData({ commit }, data) {
    commit('updateInvitationData', data);
  },
};

const mutations = {
  setPersonalityTest(state, data) {
    state.personalityTest = data;
  },
  setPersonalityTestForManager(state, formData) {
    state.personalityTest = formData.formData;
  },
  setInvitationData(state, data) {
    state.invitationData = data;
  },
  updateInvitationData(state, data) {
    state.invitationData = {
      ...state.invitationData,
      ...data,
    };
  },
};

const state = {
  personalityTest: {},
  invitationData: {},
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
