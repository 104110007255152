<template>
  <v-app class="main">
    <DetectOthersAccounts
      @no-event-button="cancelSecondaryAccount"
      @confirm-modal="confirmLogout"
      :showing-modal="detected2AutorizedAccounts"/>
    <Header
      v-if="this.$route.name !== 'main'"
      :image="smallLogo"
      :style="{background: this.$route.name === 'questionnaire' ?
             'rgb(243,244,246)' : '#F3F4F6'}"
            class="main-container"/>
    <HeaderAdmin v-if="checkRoleAdmin"/>
    <LostConnectionWindowModal
      :showing-modal="showWindowModalLostConnect"
      @retry="updateOnlineStatus(checkOnlineWithoutEvent())"
    />
    <router-view class="main__content" :key="updateContent"/>
    <Notification />
  </v-app>
</template>

<script>
import HeaderAdmin from '@components/Header/HeaderAdmin.vue';
import smallLogo from '@assets/small_logo.svg';
import Notification from '@components/Notification/Notification.vue';
import { mapGetters } from 'vuex';
import checkRole from '@helpers/adminFunction';
import Header from '@components/Header/Header.vue';
import LostConnectionWindowModal from '@innerworks_ds/innerworks-storybook-main/src/stories/WindowModals/LostConnectionWindowModal/LostConnectionWindowModal.vue';
import DetectOthersAccounts from '@innerworks_ds/innerworks-storybook-main/src/stories/WindowModals/DetectOthersAccount/DetectOthersAccounts.vue';

export default {
  name: 'App',
  components: {
    Header,
    Notification,
    HeaderAdmin,
    LostConnectionWindowModal,
    DetectOthersAccounts,
  },
  data: () => ({
    smallLogo,
    onLine: null,
    showWindowModalLostConnect: false,
    updateContent: 0,
    detected2AutorizedAccounts: false,
    timerToken: null,
  }),
  mounted() {
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
    this.updateOnlineStatus(this.checkOnlineWithoutEvent());
    window.addEventListener('storage', (x) => {
      const newValue = JSON.parse(x.newValue);
      const oldValue = JSON.parse(x.oldValue);
      if (newValue.auth && oldValue.auth
        && newValue.auth.profile.token !== oldValue.auth.profile.token) {
        window.location.reload();
      }
    }, false);
    if (this.getProfile.token) {
      this.detected2AutorizedAccounts = this.getProfile.detectedSeveralAuthorization;
      const that = this;
      this.timerToken = setTimeout(function tick() {
        that.checkToken();
        that.timerToken = setTimeout(tick, 20000);
      }, 20000);
    }
  },
  beforeDestroy() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  },
  methods: {
    checkToken() {
      this.$api.user.checkExpiredToken()
        .then((x) => {
          if (!x && this.getProfile.token) {
            this.updateContent = this.updateContent + 1;
          }
        }).catch(() => {
          if (this.getProfile.token) {
            this.updateContent = this.updateContent + 1;
            clearTimeout(this.timerToken);
          }
        });
    },
    updateOnlineStatus(e) {
      const {
        type,
      } = e;

      this.onLine = type === 'online';
    },
    checkOnlineWithoutEvent() {
      return { type: navigator.onLine ? 'online' : 'offline' };
    },
    async cancelSecondaryAccount() {
      await this.$api.user.logoutFromAnotherAccounts(false);
      this.$store.dispatch('auth/logoutOnlyCurrentDevice');
      this.$emit('close-login-modal', false);
      this.detected2AutorizedAccounts = false;
    },
    async confirmLogout() {
      const newToken = await this.$api.user.logoutFromAnotherAccounts(true);
      await this.$store.dispatch('auth/updateToken', newToken);
      this.detected2AutorizedAccounts = false;
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'getProfile.token': function (newValue) {
      if (newValue) {
        const that = this;
        this.timerToken = setTimeout(function tick() {
          that.checkToken();
          that.timerToken = setTimeout(tick, 20000);
        }, 20000);
      } else {
        clearTimeout(this.timerToken);
      }
    },
    $route() {
      if (this.getProfile.token) {
        this.checkToken();
      }
    },
    onLine(v) {
      if (v) {
        this.showWindowModalLostConnect = false;
        this.updateContent = this.updateContent + 1;
      } else {
        this.showWindowModalLostConnect = true;
      }
    },
  },
  computed: {
    ...mapGetters({
      getProfile: 'auth/getProfile',
    }),
    title() {
      if (process.env.VUE_APP_TITLE) {
        return process.env.VUE_APP_TITLE;
      }
      return 'InnerWorks';
    },
    showSecretDataModal() {
      return this.getProfile.userWithoutSecretAnswer === false;
    },
    checkRoleAdmin() {
      return !!checkRole.isAdmin();
    },
  },
  metaInfo() {
    return {
      title: this.title,
    };
  },
};
</script>

<style lang="scss">
  @import "./styles/style.scss";
  .container {
    padding: 0;
    background-color: #F3F4F6;
  }
  .main-container {
    max-width: 480px !important;
    margin: 0 auto;
    width: 100%;
  }
  .grecaptcha-badge {
    visibility: hidden;
  }
</style>
