const userBehave = {
  mainPage: 'mainPage',
  askUserName: 'askUserName',
  askUserCode: 'askUserCode',
  askUserCodeSecondTime: 'askUserCodeSecondTime',
  invitationPage: 'invitationPage',
  letsGetsStarted: 'letsGetsStarted',
  letsGetsStartedCrowd: 'letsGetsStartedCrowd',
  answerToQuestion: 'answerToQuestion',
  answerToQuestionCutVersion: 'answerToQuestionCutVersion',
  answerToQuestionSecondTimeCutVersion: 'answerToQuestionSecondTimeCutVersion',
  answerToQuestionCrowd: 'answerToQuestionCrowd',
  redirectToRegisterPage: 'redirectToRegisterPage',
  registrationOnSite: 'registrationOnSite',
  deleteAccountData: 'deleteAccountData',
  copyLink: 'copyLink',
  readyResultSharePage: 'readyResultSharePage',
};

export default userBehave;
