import http from '@utils/http';
import notifyError from '@helpers';

const generateAnonymous = () => {
  if (!localStorage.getItem('uniqueId')) {
    localStorage.setItem('uniqueId', `anonymous${Math.floor(Math.random()
      * Math.floor(Math.random() * Date.now()) * Math.random())}`);
  }

  return localStorage.getItem('uniqueId');
};

export default {
  saveSiteEvent(eventName, behaveQuestion = []) {
    return new Promise((resolve, reject) => {
      http.post('user-behave-site', { uniqueId: generateAnonymous(), eventName, userBehaveInQuestion: behaveQuestion }).then(
        ({ data }) => {
          resolve(data);
        },
      ).catch((error) => {
        notifyError(error);
        reject(error);
      });
    });
  },
  saveSiteEventCutVersion(eventName, prolificId, behaveQuestion = []) {
    return new Promise((resolve, reject) => {
      http.post('user-behave-site/cut-version', { uniqueId: prolificId, eventName, userBehaveInQuestion: behaveQuestion }).then(
        ({ data }) => {
          resolve(data);
        },
      ).catch((error) => {
        reject(error);
      });
    });
  },
  saveUserEventDuringTypingQuestions(eventName, eventDuringTypingData, behaveQuestion = []) {
    return new Promise((resolve, reject) => {
      http.post('user-behave-site/save-events-during-typing-questions', {
        uniqueId: generateAnonymous(),
        ...eventDuringTypingData,
        eventName,
        userBehaveInQuestion: behaveQuestion,
      }).then(
        ({ data }) => {
          resolve(data);
        },
      ).catch((error) => {
        reject(error);
      });
    });
  },
  saveUserEventDuringTypingQuestionsCutVersion(eventName, prolificId, eventDuringTypingData,
    behaveQuestion = []) {
    return new Promise((resolve, reject) => {
      http.post('user-behave-site/save-events-during-typing-questions-cut-version', {
        uniqueId: prolificId,
        ...eventDuringTypingData,
        eventName,
        userBehaveInQuestion: behaveQuestion,
      }).then(
        ({ data }) => {
          resolve(data);
        },
      ).catch((error) => {
        reject(error);
      });
    });
  },
};
