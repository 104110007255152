import http from '@utils/http';
import notifyError from '@helpers';

// Cut version of the app endpoints
export default {
  getSelfQuestionnaireNew(uniqueId, questionnaireID) {
    return new Promise((resolve, reject) => {
      http.get(`/questionnaire-cut-version/${questionnaireID}?uniqueId=${uniqueId}`).then(
        ({ data }) => {
          resolve(data);
        },
      ).catch((error) => {
        reject(error);
      });
    });
  },
  savePartialAnswers(answers, formData, uniqueId, questionnaireID) {
    return new Promise((resolve, reject) => {
      http.post(`/questionnaire-cut-version/save-partial-answers/${questionnaireID}`, { answers, ...formData, uniqueId }).then(
        (res) => {
          resolve(res.data);
        },
      ).catch((error) => {
        reject(error);
      });
    });
  },
  saveResult(formData, uniqueId, prolificID, questionnaireID) {
    return new Promise((resolve, reject) => {
      http.post(`/questionnaire-cut-version/save-result/${questionnaireID}`, { answers: formData, uniqueId, prolificID }).then(
        ({ data }) => {
          resolve(data);
        },
      ).catch((error) => {
        notifyError(error);
        reject(error);
      });
    });
  },
  getQuestionnaireResult(prolificID, questionnaireID) {
    return new Promise((resolve, reject) => {
      http.get(`/questionnaire-cut-version/personality-type-report/${questionnaireID}?prolificID=${prolificID}`).then(
        ({ data }) => {
          resolve(data);
        },
      ).catch((error) => {
        reject(error);
      });
    });
  },
  saveSurveyData(uniqueId) {
    return new Promise((resolve, reject) => {
      http.post(`/questionnaire-cut-version/save-survey-data?uniqueId=${uniqueId}`).then(
        ({ data }) => {
          resolve(data);
        },
      ).catch((error) => {
        notifyError(error);
        reject(error);
      });
    });
  },
  getDataCompletingQuiz(uniqueId) {
    return new Promise((resolve, reject) => {
      http.get(`/questionnaire-cut-version/get-data-completing-quiz?uniqueId=${uniqueId}`).then(
        ({ data }) => {
          resolve(data);
        },
      ).catch((error) => {
        reject(error);
      });
    });
  },
};
