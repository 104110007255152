import Vue from 'vue';
import Router from 'vue-router';
import isFreeVersion from '@helpers/func';
import helpFunction from '@helpers/helpFunction';
import checkRole from '@helpers/adminFunction';
import RouterPrefetch from 'vue-router-prefetch';
import Store from '../store';
import API from '../../api';

Vue.use(Router);
Vue.use(RouterPrefetch);

Vue.use(RouterPrefetch, {
  componentName: 'QuickLink',
});


export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '*',
      redirect: { name: 'main' },
    },
    {
      path: '/',
      redirect: { name: 'main' },
    },
    {
      path: '/main/:action?',
      name: 'main',
      props: true,
      component: () => import('@views/Main.vue'),
      beforeEnter: (to, from, next) => {
        const { token, completedQuestionnaires = [] } = Store.getters['auth/getProfile'];
        const invitationData = Store.getters['invitation/getInvitationData'];
        Store.dispatch('showComponent/turnOffHeader');
        if (checkRole.isAdmin()) {
          next('/admin/menu');
          return;
        }

        if (token && completedQuestionnaires.includes(process.env.QUESTIONNAIRE_ID)
          && !helpFunction.isFillObject(invitationData)) {
          next('/result');
          return;
        }

        if (helpFunction.isFillObject(invitationData)) {
          next();
          return;
        }

        if (token) {
          next();
          return;
        }

        next();
      },
    },
    // {
    //   path: '/activate',
    //   name: 'activate',
    //   component: () => import('@views/WelcomePage.vue'),
    //   beforeEnter: (to, from, next) => {
    //     const userAuth = Store.getters['auth/getProfile'].token;
    //
    //     if (userAuth) {
    //       next('/ask-name');
    //       return;
    //     }
    //
    //     next();
    //   },
    // },
    {
      path: '/admin/dashboard',
      name: 'adminDashboard',

      component: () => import('@views/adminViews/AdminDashboard.vue'),
      beforeEnter: (to, from, next) => {
        if (!checkRole.isAdmin()) {
          next('/');
          return;
        }

        next();
      },
    },
    {
      path: '/admin/test-dashboard',
      name: 'adminTestDashboard',

      component: () => import('@views/adminViews/AdminTestDashboard.vue'),
      beforeEnter: (to, from, next) => {
        if (!checkRole.isAdmin()) {
          next('/');
          return;
        }

        next();
      },
    },
    {
      path: '/admin/upload-employee',
      name: 'adminUploadEmployee',

      component: () => import('@views/adminViews/AdminUploadEmployee.vue'),
      beforeEnter: (to, from, next) => {
        if (!checkRole.isAdmin()) {
          next('/');
          return;
        }

        next();
      },
    },
    {
      path: '/manage/panel',
      name: 'adminPanel',

      component: () => import('@views/managePage/AdminPanel.vue'),
      beforeEnter: (to, from, next) => {
        if (!checkRole.isAdminAndSuperUserAndSeleniumUser()) {
          next('/');
          return;
        }

        next();
      },
    },
    {
      path: '/questionnaire',
      name: 'questionnaire',
      component: () => import('@views/NewQuestions.vue'),
      beforeEnter: (to, from, next) => {
        // eslint-disable-next-line no-underscore-dangle
        // const userAuth = Store.getters['auth/getProfile'].token;
        const { completedQuestionnaires = [], token, name } = Store.getters['auth/getProfile'];
        const getInvitationData = Store.getters['invitation/getInvitationData'];

        if (checkRole.isAdmin()) {
          next('/admin/menu');
          return;
        }

        if (checkRole.isSuperUser() || checkRole.isSeleniumUser()) {
          next('/manage/panel');
          return;
        }

        if (getInvitationData.userId) {
          next();
          return;
        }

        if (isFreeVersion() && !completedQuestionnaires.includes(process.env.QUESTIONNAIRE_ID)) {
          next();
        }

        if (!name && !Object.keys(getInvitationData).length) {
          next('/ask-name');
          return;
        }

        if (token && completedQuestionnaires.includes(process.env.QUESTIONNAIRE_ID)) {
          next('result');
        } else if (token && !completedQuestionnaires.includes(process.env.QUESTIONNAIRE_ID)) {
          Store.dispatch('showComponent/gotToStepInitial');
          next();
        }

        if (!token && completedQuestionnaires.includes(process.env.QUESTIONNAIRE_ID)) {
          next('result');
        } else if (!token && !completedQuestionnaires.includes(process.env.QUESTIONNAIRE_ID)) {
          Store.dispatch('showComponent/gotToStepInitial');
          next();
        }

        next();
      },
    },
    {
      path: `/questionnaire/invitation/${process.env.QUESTIONNAIRE_ID}/u2/:id`,
      name: 'questionnaire-invitation',
      meta: { invitationType: process.env.QUESTIONNAIRE_ID },
      component: () => import('@views/Questions.vue'),
      beforeEnter: async (to, from, next) => {
        const { token, id } = Store.getters['auth/getProfile'];
        let isOwnInvitationLink;
        let userId;

        try {
          if (token) {
            userId = id;
          } else {
            userId = localStorage.getItem('uniqueId');
          }

          isOwnInvitationLink = await API.questionnaire
            .checkInvitationLinkIsNotOwn(userId, to.params.id);
        } catch (e) {
          isOwnInvitationLink = false;
        }

        if (isOwnInvitationLink) {
          await Store.dispatch('invitation/setInvitationData',
            {
              questionnaireId: to.meta.invitationType,
              userId: to.params.id,
            });
          next('/main');
        } else {
          next('/invitation-link-is-own');
        }
      },
    },
    {
      path: '/admin/menu',
      name: 'adminMenu',

      component: () => import('@views/adminViews/AdminMenu.vue'),
      beforeEnter: (to, from, next) => {
        // eslint-disable-next-line no-underscore-dangle

        if (checkRole.isAdmin()) {
          next();
          return;
        }
        if (!checkRole.isAdmin()) {
          next('/');
          return;
        }
        next();
      },
    },
    {
      path: '/invitation-link-is-own',
      name: 'invitationLinkIsOwn',
      component: () => import('@views/InvitationLinkIsOwn.vue'),
      beforeEnter: (to, from, next) => {
        const { completedQuestionnaires = [] } = Store.getters['auth/getProfile'];

        if (!completedQuestionnaires.includes(process.env.QUESTIONNAIRE_ID)) {
          next('/main');
          return;
        }

        if (from.name === 'share-info' || from.path === '/') {
          next();
        } else {
          next('main');
        }
      },
    },
    {
      path: '/invintation-report/:userId',
      name: 'invintation-report',

      props: true,
      component: () => import('@views/InvintationReport.vue'),
      beforeEnter: (to, from, next) => {
        // eslint-disable-next-line no-underscore-dangle
        const userAuth = Store.getters['auth/getProfile'].token;
        const invitation = Store.getters['invitation/getPersonalityTest'].result;
        let invitationManager;
        if (Store.getters['invitation/getPersonalityTestForManager']) {
          invitationManager = Store.getters['invitation/getPersonalityTestForManager'];
        }

        if (!invitationManager && (invitation !== undefined)) {
          next('/');
        }

        if (!userAuth) {
          next('/');
        }

        next();
      },
    },
    {
      path: '/create-password',
      name: 'create-password',

      component: () => import('@views/CreatePassword.vue'),
      beforeEnter: (to, from, next) => {
        // eslint-disable-next-line no-underscore-dangle
        const userAuth = Store.getters['auth/getProfile'].token;

        if (userAuth) {
          next('/questionnaire');
          return;
        }

        next();
      },
    },
    {
      path: '/create-new-password',
      name: 'create-new-password',

      component: () => import('@views/CreateNewPassword.vue'),
      beforeEnter: (to, from, next) => {
        // eslint-disable-next-line no-underscore-dangle
        const userAuth = Store.getters['auth/getProfile'].token;

        if (userAuth) {
          next('/questionnaire');
          return;
        }

        next();
      },
    },
    {
      path: '/reset-password',
      name: 'reset-password',

      component: () => import('@views/ResetPassword.vue'),
      beforeEnter: (to, from, next) => {
        const userAuth = Store.getters['auth/getProfile'].token;

        if (userAuth) {
          next('/questionnaire');
          return;
        }

        next();
      },
    },
    {
      path: '/building-credibility-score',
      name: 'building-credibility-score',
      component: () => import('@views/BuildingYourCredibilityScore.vue'),
      beforeEnter: (to, from, next) => {
        // eslint-disable-next-line no-underscore-dangle
        const { userWithCreditCard, userWithIdentificationCard, token } = Store.getters['auth/getProfile'];

        if (token && (userWithCreditCard && userWithIdentificationCard)) {
          next('/questionnaire');
        }

        if (!token) {
          next('/');
        }

        if (isFreeVersion()) {
          next('/questionnaire');
        }

        next();
      },
    },
    {
      path: '/confirm-user-details',
      name: 'confirm-user-details',
      component: () => import('@views/ConfirmUserDetails.vue'),
      beforeEnter: (to, from, next) => {
        // eslint-disable-next-line no-underscore-dangle
        const userAuth = Store.getters['auth/getProfile'].token;

        if (userAuth) {
          next('/questionnaire');
          return;
        }

        next();
      },
    },
    // {
    //   path: '/landing/:login?',
    //   name: 'landing',
    //   component: () => import('@views/WelcomePage.vue'),
    //   beforeEnter: (to, from, next) => {
    //     // eslint-disable-next-line no-underscore-dangle
    //     // const { token, name } = Store.getters['auth/getProfile'];
    //     // const invitationData = Store.getters['invitation/getInvitationData'];
    //
    //     // if (token && helpFunction.isFillObject(invitationData)) {
    //     //   next('/questionnaire');
    //     //   return;
    //     // }
    //
    //     // if (!name && token) {
    //     //   next('/ask-name');
    //     // }
    //
    //     // if (name && token) {
    //     //   next('/questionnaire');
    //     //   return;
    //     // }
    //
    //     next();
    //   },
    // },
    {
      path: '/enter-security-code',
      name: 'enter-security-code',

      component: () => import('@views/EnterSecurityCode.vue'),
      beforeEnter: (to, from, next) => {
        // eslint-disable-next-line no-underscore-dangle
        const userAuth = Store.getters['auth/getProfile'].token;

        if (userAuth) {
          next('/questionnaire');
          return;
        }

        next();
      },
    },
    {
      path: '/ask-name',
      name: 'ask-name',

      component: () => import('@views/AskName.vue'),
      beforeEnter: (to, from, next) => {
        // eslint-disable-next-line no-underscore-dangle
        const { token, name } = Store.getters['auth/getProfile'];

        if (name && token) {
          next('/questionnaire');
          return;
        }

        next();
      },
    },
    {
      path: '/about',
      name: 'about',

      component: () => import('@views/AboutUs.vue'),
      beforeEnter: (to, from, next) => {
        Store.dispatch('showComponent/showHeaderMain');
        next();
      },
    },
    {
      path: '/trust',
      name: 'trust',
      component: () => import('@views/TrustStart.vue'),
      beforeEnter: (to, from, next) => {
        const { completedQuestionnaires = [], token } = Store.getters['auth/getProfile'];

        if (token) {
          next('result');
        }

        if (!completedQuestionnaires.includes(process.env.QUESTIONNAIRE_ID)
          || !localStorage.getItem('uniqueId')) {
          Store.dispatch('showComponent/gotToStepInitial');
          next('/main');
          return;
        }

        Store.dispatch('showComponent/hideHeader');
        next();
      },
    },
    {
      path: '/trust/explain',
      name: 'explain',
      component: () => import('@views/TrustExplain.vue'),
      beforeEnter: (to, from, next) => {
        const { completedQuestionnaires = [], token } = Store.getters['auth/getProfile'];

        if (token) {
          next('result');
        }

        if (!completedQuestionnaires.includes(process.env.QUESTIONNAIRE_ID)
          || !localStorage.getItem('uniqueId')) {
          Store.dispatch('showComponent/gotToStepInitial');
          next('/main');
          return;
        }

        Store.dispatch('showComponent/hideHeader');
        next();
      },
    },
    {
      path: '/share-result',
      name: 'sharing-result',
      component: () => import('@views/NotificationReadyResult.vue'),
      beforeEnter: (to, from, next) => {
        const { completedQuestionnaires = [], name, token } = Store.getters['auth/getProfile'];

        if (token) {
          next('result');
        }

        if (!name) {
          next('ask-name');
        }

        if (!completedQuestionnaires.includes(process.env.QUESTIONNAIRE_ID)
          || !localStorage.getItem('uniqueId')) {
          Store.dispatch('showComponent/gotToStepInitial');
          next('/main');
          return;
        }

        Store.dispatch('showComponent/hideHeader');
        next();
      },
    },
    {
      path: '/share-result/confirm-email',
      name: 'confirm-email',
      component: () => import('@views/ConfirmNotificationEmail.vue'),
      beforeEnter: (to, from, next) => {
        const { completedQuestionnaires = [], token } = Store.getters['auth/getProfile'];

        if (token) {
          next('result');
        }

        if (!completedQuestionnaires.includes(process.env.QUESTIONNAIRE_ID)
          || !localStorage.getItem('uniqueId')) {
          Store.dispatch('showComponent/gotToStepInitial');
          next('/main');
          return;
        }

        Store.dispatch('showComponent/hideHeader');
        next();
      },
    },
    {
      path: '/share-result/info',
      name: 'share-info',
      props: true,
      component: () => import('@views/ShareInfo.vue'),
      beforeEnter: (to, from, next) => {
        const { completedQuestionnaires = [], token } = Store.getters['auth/getProfile'];

        if (token) {
          next('result');
        }

        if (!completedQuestionnaires.includes(process.env.QUESTIONNAIRE_ID)
          || !localStorage.getItem('uniqueId')) {
          Store.dispatch('showComponent/gotToStepInitial');
          next('/main');
          return;
        }

        Store.dispatch('showComponent/hideHeader');
        next();
      },
    },
    {
      path: '/result',
      name: 'result',

      component: () => import('@views/Result.vue'),
      beforeEnter: (to, from, next) => {
        const { completedQuestionnaires = [], token, name } = Store.getters['auth/getProfile'];
        Store.dispatch('showComponent/turnOffHeader');
        if (from.query['result-page']) {
          next();
          return;
        }

        if (token && completedQuestionnaires && !name) {
          next('ask-name');
        }

        if (checkRole.isAdmin()) {
          next('/admin/menu');
          return;
        }

        if (checkRole.isSuperUser() || checkRole.isSeleniumUser()) {
          next('/manage/panel');
          return;
        }

        next();
      },
    },
    {
      path: '/success-invitation',
      name: 'success-invitation',
      component: () => import('@views/SuccessInvitation.vue'),
      beforeEnter: (to, from, next) => {
        const getInvitationData = Store.getters['invitation/getInvitationData'];

        if (!getInvitationData) {
          next('main');
        }

        Store.dispatch('showComponent/hideHeader');
        next();
      },
    },
  ],
});
