<template>
   <ChatPageWindowModal
     v-if="chatIsOpen"
     :showing-modal="showModal"
     :scriptLoaded="scriptLoaded"
     :invalidEmail="invalidEmail"
     @validation-completed="startChat"
     @show-modal="closeModal($event)"
    />
</template>

<script>
import { validationMixin } from 'vuelidate';
import chatImg from '@assets/chat.svg';
import tawkChat from '@helpers/tawkChat';
import helpFunction from '@helpers/helpFunction';
import ChatPageWindowModal from '@innerworks_ds/innerworks-storybook-main/src/stories/WindowModals/ChatPageWindowModal/ChatPageWindowModal.vue';
import { mapGetters } from 'vuex';

const { required } = require('vuelidate/lib/validators');

export default {
  name: 'Chat',
  mixins: [validationMixin],
  components: {
    ChatPageWindowModal,
  },
  validations: {
    formData: {
      email: {
        required,
        mustBeCool: helpFunction.mustBeCool,
      },
      name: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters({
      getProfile: 'auth/getProfile',
    }),
  },
  data: () => ({
    formData: {
      name: '',
      email: '',
    },
    showModal: true,
    timer: null,
    chatIsOpen: false,
    scriptLoaded: false,
    chatImg,
    invalidEmail: null,
  }),
  watch: {
    invalidEmail(value) {
      if (value) {
        window.Tawk_API.minimize();
        window.Tawk_API.hideWidget();
      }
    },
  },
  mounted() {
    const { tawkChatEmail, tawkChatName } = this.getProfile;
    if (tawkChatEmail) {
      this.startChat({ email: tawkChatEmail, name: tawkChatName });
    }

    if (window.Tawk_API && window.Tawk_API.isChatOngoing) {
      window.Tawk_API.maximize();
    } else if (!tawkChatEmail) {
      this.chatIsOpen = true;
    }
  },
  methods: {
    closeModal(event) {
      this.$emit('close-chat-modal', event);
    },
    startChat(formData) {
      this.formData = {
        email: formData.email,
        name: formData.name,
      };
      tawkChat(formData.email, formData.name);
      if (this.scriptLoaded) {
        window.Tawk_API.setAttributes({
          name: formData.name,
          email: formData.email,
        }, (error) => {
          this.checkError(error);
        });
      }

      window.Tawk_API.onLoad = () => {
        window.Tawk_API.setAttributes({
          name: formData.name,
          email: formData.email,
        }, (error) => {
          this.checkError(error);
        });

        this.scriptLoaded = true;
      };
    },
    checkError(error) {
      if (!error) {
        window.Tawk_API.maximize();
        window.Tawk_API.showWidget();
        this.invalidEmail = null;
        this.runTimer();
        if (this.getProfile.token
          && (!this.getProfile.tawkChatEmail || this.getProfile.tawkChatName)) {
          this.saveTawkChatData({
            tawkChatEmail: this.formData.email,
            tawkChatName: this.formData.name,
          });
        }
        this.$emit('close-chat-modal', true);
      } else {
        window.Tawk_API.minimize();
        this.invalidEmail = error;
      }
    },
    saveTawkChatData(tawkData) {
      this.$api.auth.saveTawkChatData(tawkData).then((x) => {
        const profile = {
          ...this.getProfile,
          tawkChatEmail: x.tawkChatEmail,
          tawkChatName: x.tawkChatName,
        };

        this.$store.dispatch('auth/updateProfile', profile);
      });
    },
    runTimer() {
      this.timer = setInterval(() => {
        if (document.getElementById('tawkId')) {
          const widget = document.querySelectorAll('[title="chat widget"]')[1];
          if (widget) {
            widget.contentWindow.document.getElementById('tawk-form-container')
              .getElementsByTagName('button')[0].click();
            clearInterval(this.timer);
          }
        }
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
  .container {
    height: 100%;
    position: relative;
  }
  .content-chat-page {
    font-family: $newDefaultFont;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin: 0 30px 120px 20px;
  }

  .button-start-chat {
    background-color: #7811C9;
    padding: 12px 61px 12px 61px;
    border-radius: 5px;
    width: 100%;
    bottom: 0;
    height: 80px;
    position: fixed;
    max-width: 480px;
  }
  .image-chat-page {
    width: 100%;
    height: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }

  @media (max-height: $xsMaxHeight) {
    .information-content {
      margin-bottom: 10px;
    }
  }
</style>
