<template>
  <div class="header">
    <LoginAndRegisterWindowModal
      v-if="isOpenLoginRegisterModal"
      @close-login-modal="closeLoginRegisterModal"
    />
    <div class="question" v-if="!hiddenHeader || showHeaderAllTime"
         :style="{background: this.$route.name === 'questionnaire' ? getLastAnswer.color : ''}">
      <WarningGoingHomeModal
        :showing-modal="isOpenWarningModal"
        @show-modal="closeWarningModalModal"/>
      <DeleteUser
        :showing-modal="dialogDeleteAccount"
        @delete-user="deleteUser"
        @show-modal="closeDeleteUser"
        @open-delete-user-modal="openUserDeleteModal"/>
      <DeleteUserThanking
        :showingModal="dialogDeleteAccountSecond"
        @redirect-to-main="redirectToMain"
        @show-modal="closeDeleteUserThankingWindowModal"
      />
      <TermAndConditionsNewModalWithExpands
        :showing-modal="showWindowModalTermsAndConditions"
        @show-modal="closeTheWindow($event)"
        :open-element="openExpandForTermAndConditionsWindowModal"
      />
      <div class="logo-innerworks" v-if="chooseHeader">
        <span class="logo-block" @click="redirectToMainPage">
          <img
            :src="image"
            alt="logo innerworks"
          />
        </span>
        <FAQHeader
          :faqQuestions="faqQuestions"
          :showing-modal="isOpenModal"
          :from-header="true"
          @show-modal="closeModal($event)"/>
        <BurgerMenu
          @open-about-us-modal="openAboutUsModal($event)"
          @redirect="redirect($event)"
          @toggle="test"
          :get-profile="getProfile"
          :questionnaire-id="questionnaireId"
          @open-login-register-modal="openLoginRegisterModal($event)"
          @open-chat-modal="openChatModal($event)"
          @remove-user-data="openUserDeleteModal"
          @open-term-conditions-modal-policy="termAndConditionModalPolicy"
          @open-term-conditions-modal-privacy="termAndConditionModalPrivacy"
          @open-term-conditions-modal-term="termAndConditionModalTerm"
          @open-faq-modal="openFAQModal($event)"/>
        <Chat
          v-if="isOpenChatModal"
          @close-chat-modal="closeChatModal"
        />
        <AboutUsWindowModal
          :showing-modal="isOpenAboutUsModal"
          @show-modal="openAboutUsModal"
        />
      </div>
      <!--      second menu-->
      <div class="second-header" v-else>
        <QuestionProgressBar
          v-if="checkProgressBar"
          :number-of-checkpoint-screen="getQuestionnaireData.numberCheckPointScreen"
          :question-before-checkpoint-screen="getQuestionnaireData.questionBeforeCheckpointScreen"
          :current-index="getQuestionnaireData.currentQuestionNumber"
          :last-question-number="getQuestionnaireData.countOfQuestion"/>
        <v-btn
          icon dark
          @click="checkCutVersion"
          color="#7811C9"
          class="custom-dialog-close-icon">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import FAQHeader from '@innerworks_ds/innerworks-storybook-main/src/stories/FrequentlyAskedQuestions/FAQHeader/FAQHeader.vue';
import TermAndConditionsNewModalWithExpands from '@innerworks_ds/innerworks-storybook-main/src/stories/WindowModals/TermAndConditionsNewModalWithExpands/TermAndConditionsNewModalWithExpands.vue';
import WarningGoingHomeModal from '@innerworks_ds/innerworks-storybook-main/src/stories/WindowModals/WarningGoingHomeModal/WarningGoingHomeModal.vue';
import AboutUsWindowModal from '@innerworks_ds/innerworks-storybook-main/src/stories/WindowModals/AboutUsWindowModal/AboutUsWindowModal.vue';
import BurgerMenu from '@innerworks_ds/innerworks-storybook-main/src/stories/BurgerMenu/BurgerMenu.vue';
import Chat from '@components/Сhat/Chat.vue';
import LoginAndRegisterWindowModal from '@components/LoginAndRegisterModal/LoginAndRegisterWindowModal.vue';
import QuestionProgressBar from '@innerworks_ds/innerworks-storybook-main/src/stories/ProgressBar/QuestionProgressBar.vue';
import helpFunction from '@helpers/helpFunction';
import DeleteUser from '@innerworks_ds/innerworks-storybook-main/src/stories/WindowModals/DeleteUser/DeleteUser.vue';
import userBehave from '@constants/userBehave';
import DeleteUserThanking from '@innerworks_ds/innerworks-storybook-main/src/stories/WindowModals/DeleteUser/DeleteUserThanking.vue';

export default {
  name: 'Header',
  components: {
    Chat,
    BurgerMenu,
    WarningGoingHomeModal,
    FAQHeader,
    AboutUsWindowModal,
    LoginAndRegisterWindowModal,
    QuestionProgressBar,
    DeleteUser,
    DeleteUserThanking,
    TermAndConditionsNewModalWithExpands,
  },
  props: {
    showHeaderAllTime: {
      type: Boolean,
      default() {
        return false;
      },
    },
    image: {
      type: String,
    },
    showWarningsModal: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  created() {
    this.$api.faq.fetchFAQ().then((data) => {
      this.faqQuestions = data;
    });
  },
  computed: {
    ...mapGetters({
      getProfile: 'auth/getProfile',
      getQuestionnaireData: 'quiz/getQuestionnaireData',
      hiddenHeader: 'showComponent/hiddenHeader',
      getInvitationData: 'invitation/getInvitationData',
      getLastAnswer: 'quiz/getLastAnswer',
    }),
    questionnaireId() {
      return process.env.QUESTIONNAIRE_ID;
    },
    chooseHeader() {
      return !(this.$route.name === 'ask-name' || this.$route.name === 'questionnaire' || process.env.CUT_VERSION);
    },
    checkProgressBar() {
      return this.getQuestionnaireData.showProgressBar && this.$route.name === 'questionnaire';
    },
  },
  watch: {
    '$route.name': {
      handler: (name) => {
        if (window.Tawk_API && name === 'questionnaire') {
          window.Tawk_API.minimize();
          window.Tawk_API.hideWidget();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data: () => ({
    showPopUp: false,
    showMainMenu: false,
    isOpenModal: false,
    isOpenWarningModal: false,
    burgerMenu: false,
    faqQuestions: {},
    isOpenChatModal: false,
    isOpenAboutUsModal: false,
    isOpenLoginRegisterModal: false,
    dialogDeleteAccount: false,
    dialogDeleteAccountSecond: false,
    showWindowModalTermsAndConditions: false,
    openExpandForTermAndConditionsWindowModal: '',
  }),
  mounted() {
    if (this.$router.currentRoute.name === 'main') {
      this.$store.dispatch('showComponent/mainPage');
      this.showMainMenu = true;
    }
  },
  methods: {
    termAndConditionModalPolicy() {
      this.openExpandForTermAndConditionsWindowModal = 'policy';
      this.showWindowModalTermsAndConditions = true;
    },
    termAndConditionModalPrivacy() {
      this.openExpandForTermAndConditionsWindowModal = 'privacy';
      this.showWindowModalTermsAndConditions = true;
    },
    termAndConditionModalTerm() {
      this.openExpandForTermAndConditionsWindowModal = 'term';
      this.showWindowModalTermsAndConditions = true;
    },
    closeDeleteUserThankingWindowModal() {
      this.dialogDeleteAccountSecond = false;
    },
    async redirectToMain() {
      this.dialogDeleteAccountSecond = false;
      await this.$router.push('main');
    },
    async openUserDeleteModal() {
      await this.$api.userBehaveSite.saveSiteEvent(userBehave.deleteAccountData);
      this.dialogDeleteAccount = true;
    },
    async deleteUser() {
      if (this.getProfile.token) {
        const reason = '';
        await this.$api.auth.removeUserAccount(reason).then(() => this.$store.dispatch('auth/logout'));
      } else {
        await this.$api.user.removeUserDataForUnregistered().then(() => {
          this.$store.dispatch('auth/logoutOnlyCurrentDevice');
          localStorage.setItem('uniqueId', `anonymous${Math.floor(Math.random()
            * Math.floor(Math.random() * Date.now()) * Math.random())}`);
        });
      }
      this.dialogDeleteAccountSecond = true;
    },
    closeTheWindow(e) {
      if (!e) {
        this.openExpandForTermAndConditionsWindowModal = '';
        this.showWindowModalTermsAndConditions = false;
      }
      this.showWindowModalTermsAndConditions = e;
    },
    closeDeleteUser(value) {
      this.dialog = value;
    },
    test(event) {
      console.log(event);
    },
    closeLoginRegisterModal() {
      this.isOpenLoginRegisterModal = false;
    },
    openLoginRegisterModal() {
      this.isOpenLoginRegisterModal = true;
    },
    openChatModal() {
      if (window.Tawk_API) {
        window.Tawk_API.showWidget();
        window.Tawk_API.maximize();
      } else {
        this.isOpenChatModal = true;
      }
    },
    closeChatModal() {
      this.isOpenChatModal = false;
    },
    openAboutUsModal(value) {
      this.isOpenAboutUsModal = value;
    },
    async redirect(event) {
      await this.$store.dispatch('behaviorComponents/changeCrowdTab', true);
      if (window.Tawk_API) {
        window.Tawk_API.endChat();
        setTimeout(() => {
          window.Tawk_API.minimize();
          window.Tawk_API.hideWidget();
        }, 1000);
      }
      await this.$store.dispatch('auth/logout');
      await this.$router.push(event);
    },
    closeModal(value) {
      this.isOpenModal = value;
    },
    closeWarningModalModal(value, updateData) {
      this.isOpenWarningModal = value;

      if (value === false && updateData) {
        this.$router.push({ name: 'main' });
        this.$store.dispatch('invitation/setInvitationData', {});
        localStorage.setItem('uniqueId', `anonymous${Math.floor(Math.random()
          * Math.floor(Math.random() * Date.now()) * Math.random())}`);
      }
    },
    openFAQModal(e) {
      this.isOpenModal = e;
    },
    backToAnonymous() {
      this.$store.dispatch('showComponent/gotToStepInitial');
    },
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push({ name: 'main' });
    },
    headerRegistration() {
      const route = this.$route.path.split('/').filter(x => x !== '');
      return route[0] === 'login';
    },
    redirectToRegistration() {
      this.$router.push({
        name: 'main',
        params: {
          slide: 3,
        },
      });
    },
    redirectToLogIn() {
      this.$router.push('login');
    },
    userLogOut() {
      this.$store.dispatch('auth/logout');
    },
    closePopUp() {
      this.showPopUp = false;
    },
    register() {
      this.$router.push({
        name: 'registration',
      });
    },
    checkCutVersion() {
      if (process.env.CUT_VERSION) {
        this.redirectToMainCurVersion();
      } else {
        this.redirectToMainPage();
      }
    },
    redirectToMainPage() {
      if (this.getProfile.token && !helpFunction.isFillObject(this.getInvitationData)) {
        this.$router.push({ name: 'result' });
        this.isOpenWarningModal = false;
      } else if (this.getProfile.token && helpFunction.isFillObject(this.getInvitationData)) {
        this.$store.dispatch('invitation/setInvitationData', {});
        this.$router.push({ name: 'result' });
      } else if (helpFunction.isFillObject(this.getInvitationData)) {
        this.$store.dispatch('invitation/setInvitationData', {});
        this.$router.push({
          name: 'main',
          params: {
            slide: 3,
          },
        });
      } else if (this.checkProgressBar) {
        this.isOpenWarningModal = true;
      } else {
        this.$router.push({ name: 'main' });
      }
    },
    redirectToMainCurVersion() {
      this.$router.push({ name: 'main' });
    },
  },
};

</script>

<style lang="scss" scoped>
::v-deep.header {
  .question {
    display: flex;
    transition: all 0.5s;
  }
  .progress-bar {
    background: transparent;
  }
  .logo-innerworks {
    padding-left: 25px;
    padding-top: 20px;
    width: 100%;
    position: relative;
    margin-bottom: 20px;
  }

  .logo-block {
    cursor: pointer;
  }

  .second-header {
    height: 72px;
    padding-left: 25px;
    padding-top: 25px;
    width: 100%;
    position: relative;
  }
  .mdi-close {
    height: 28px !important;
    width: 28px !important;
    font-size: 28px !important;
  }

  .custom-dialog-close-icon {
    position: absolute;
    right: 20px;
    top: 20px;
  }
}
</style>
