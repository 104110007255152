import http from '@utils/http';
import notifyError from '@helpers';

export default {
  fetchQuestionnaireNew(uniqueId) {
    return new Promise((resolve, reject) => {
      http.get(`questionnaire/new/${process.env.QUESTIONNAIRE_ID}?uniqueId=${uniqueId}`).then(
        ({ data }) => {
          resolve(data);
        },
      ).catch((error) => {
        reject(error);
      });
    });
  },
  fetchInvitationQuestionnaire(u2id, u1Id) {
    return new Promise((resolve, reject) => {
      http.get(`questionnaire/invitation/new/${process.env.QUESTIONNAIRE_ID}/u2/${u2id}?u1Id=${u1Id}`).then(
        ({ data }) => {
          resolve(data);
        },
      ).catch((error) => {
        reject(error);
      });
    });
  },
  saveAnswer(formData) {
    return new Promise((resolve, reject) => {
      http.post(`questionnaire/new/${process.env.QUESTIONNAIRE_ID}`, formData).then(
        ({ data }) => {
          resolve(data);
        },
      ).catch((error) => {
        notifyError(error);
        reject(error);
      });
    });
  },
  saveAnswerFreeVersionNew(formData, uniqueId) {
    return new Promise((resolve, reject) => {
      http.post(`questionnaire/new/free-version/${process.env.QUESTIONNAIRE_ID}`, { answers: formData, uniqueId }).then(
        ({ data }) => {
          resolve(data);
        },
      ).catch((error) => {
        notifyError(error);
        reject(error);
      });
    });
  },
  saveInvitationAnswer(formData, id, uniqueId, requestParam, u1Email, notificationCrowdEmails) {
    return new Promise((resolve, reject) => {
      http.post(`/questionnaire/invitation/new/${process.env.QUESTIONNAIRE_ID}/u2/${id}${requestParam}`, {
        answers: formData, uniqueId, u1Email, notificationU1Email: notificationCrowdEmails,
      }).then(
        (res) => {
          resolve(res.data);
        },
      ).catch((error) => {
        reject(error);
      });
    });
  },
  getInvitationAnswer(id) {
    return new Promise((resolve, reject) => {
      http.post(`/questionnaire/get-invitation/new/${process.env.QUESTIONNAIRE_ID}/u2/${id}`).then(
        (res) => {
          resolve(res.data);
        },
      ).catch((error) => {
        notifyError(error);
        reject(error);
      });
    });
  },
  getListInvitation() {
    return new Promise((resolve, reject) => {
      http.post(`/questionnaire/get-list-invitation/new/${process.env.QUESTIONNAIRE_ID}`).then(
        (res) => {
          resolve(res.data);
        },
      ).catch((error) => {
        notifyError(error);
        reject(error);
      });
    });
  },
  savePartialAnswers(answers, formData, uniqueId) {
    return new Promise((resolve, reject) => {
      http.post(`/questionnaire/save-partial-answers/${process.env.QUESTIONNAIRE_ID}`, { answers, ...formData, uniqueId }).then(
        (res) => {
          resolve(res.data);
        },
      ).catch((error) => {
        reject(error);
      });
    });
  },
  calculatePartialResult(formData) {
    return new Promise((resolve, reject) => {
      http.post(`/questionnaire/partial-result/${process.env.QUESTIONNAIRE_ID}`, formData).then(
        (res) => {
          resolve(res.data);
        },
      ).catch((error) => {
        reject(error);
      });
    });
  },
  checkInvitationLinkIsNotOwn(uniqueId, invitationId) {
    return new Promise((resolve, reject) => {
      http.get(`/questionnaire/invitation/check-invitation-link-not-own/${process.env.QUESTIONNAIRE_ID}?uniqueId=${uniqueId}&invitationId=${invitationId}`).then(
        (res) => {
          resolve(res.data);
        },
      ).catch((error) => {
        reject(error);
      });
    });
  },
};
