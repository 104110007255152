import http from '@utils/http';

export default {
  saveNotificationCrowdEmails(formData) {
    return new Promise((resolve, reject) => {
      http.post(`user/save-notification-crowd-emails/${process.env.QUESTIONNAIRE_ID}`, formData).then(
        ({ data }) => {
          resolve(data);
        },
      ).catch((error) => {
        reject(error);
      });
    });
  },
  saveUserName(userName) {
    return new Promise((resolve, reject) => {
      http.get(`user/save-user-name?userName=${userName}`).then(
        ({ data }) => {
          resolve(data);
        },
      ).catch((error) => {
        reject(error);
      });
    });
  },
  logoutFromAnotherAccounts(logoutFromAnotherAccounts, fingerPrint) {
    return new Promise((resolve, reject) => {
      http.post(`/user/logout-from-another-accounts?logoutFromAnotherAccounts=${logoutFromAnotherAccounts}`, fingerPrint).then(
        ({ data }) => {
          resolve(data);
        },
      ).catch((error) => {
        reject(error);
      });
    });
  },
  logout() {
    return new Promise((resolve, reject) => {
      http.get('/user/logout').then(
        ({ data }) => {
          resolve(data);
        },
      ).catch((error) => {
        reject(error);
      });
    });
  },
  checkExpiredToken() {
    return new Promise((resolve, reject) => {
      http.get('user/check-expired-token').then(
        ({ data }) => {
          resolve(data);
        },
      ).catch((error) => {
        reject(error);
      });
    });
  },
  removeUserDataForUnregistered() {
    return new Promise((resolve, reject) => {
      http.delete(`user/remove-user-data-for-unregistered?uniqueId=${localStorage.getItem('uniqueId')}`).then(
        ({ data }) => {
          resolve(data);
        },
      ).catch((error) => {
        reject(error);
      });
    });
  },
};
